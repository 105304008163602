import Region from "lib/region"
import util from "lib/util"

export default {
  name: "sendback",
  data() {
    return {
      kdNum: util.query("kdNum"),
      backExpId: util.query("backExpId"),
      orderSource: util.query("orderSource"),
      form: {
        send: {
          name: "",
          mobile: "",
          fixedPhone: "",
          xzq: "",
          xzqArr: [],
          address: "",
          type: 'mobile'
        }
      },
      recgnizeText: "",
      xzq: [],
      merchant: {fieldList: []},
      showNotice: false,
      showRecgnize: false
    }
  },
  methods: {
    switchPhone() {
      this.form.send.type = this.form.send.type == 'mobile' ? 'fixedPhone' : 'mobile'
    },
    locate(showFail) {
      util.locate({
        success: (position, locateInfo) => {
          let gotaddr = locateInfo.addressComponent || {}
          util.setSession("gotaddr", {
            addr: locateInfo.formattedAddress || "",
            xzq: [gotaddr.province || "", gotaddr.city || "", gotaddr.district || ""].join(",")
          })
          this.recgnize(locateInfo.formattedAddress)
        },
        fail: () => {
          showFail && this.$toast("定位失败")
        }
      }, this)
    },
    handleRecgnize(event) {
      if(!this.recgnizeText) return this.$toast("请输入要识别的文本")
      event.hide()
      this.recgnize()
    },
    recgnize(text) {
      if(!text && !this.recgnizeText) return
      this.$http.get("/order/open/sent.do?method=infosplit", {
        data: {
          content: text || this.recgnizeText
        }
      }).then(result => {
        var data = result.data[0], detail = this.form.send
        if(!text) {
          data.name && (detail.name = data.name)
          if(data.mobile && data.mobile.length) {
            if(/^1\d{10}$/.test(data.mobile[0])) {
              detail.mobile = data.mobile[0]
              detail.fixedPhone = ""
            } else {
              detail.fixedPhone = data.mobile[0]
              detail.mobile = ""
            }
          }
        }
        data.xzq && data.xzq.fullName && (this.xzq = data.xzq.fullName.split(","))
        data.addr && (detail.address = data.addr)
      })
    },
    showPicker() {
      this.region.show(this.xzq)
    },
    submit() {
      let form = this.form, send = form.send
      if(!send.name) return this.$toast("请填写寄件人名称")
      if(send.type == 'mobile') {
        if(!send.mobile) return this.$toast('请填写联系电话')
        if(!/1\d{10}/.test(send.mobile)) return this.$toast("请填写正确的手机号")
      } else {
        if(!send.fixedPhone) return this.$toast('请填写座机号码')
        if(send.fixedPhone.length < 5) return this.$toast("请填写正确的座机号码")
      }
      if(!send.xzq) return this.$toast('请选择省市区')
      if(!send.address) return this.$toast("请填写详细地址")
      if (/台湾|香港|澳门/.test(send.xzq)) {
        return this.$confirm({
          content: "抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！",
          type: 'alert',
          confirmText: "我知道了"
        })
      }
      if(send.address.length < 4) {
        return this.$toast("详细地址不能少于4个字")
      }
      let formList = this.merchant.fieldList
      for(var i = 0; i < formList.length; i++) {
        if(formList[i].required && !formList[i].value) {
          return this.$toast("请填写" + formList[i].fieldName)
        }
      }  
      this.doSubmit()
    },
    goOrders() {
      this.navigator.push({
        name: "orderList",
        query: {
          sntSign: this.merchant.sntSign,
          kdNum: this.merchant.kdNum
        }
      })
    },
    doSubmit() {
      util.setSession("sendbackMerchant", this.merchant)
      util.setSession("sendbackForm", this.form)
      this.navigator.push({
        name: "sendBackDispatch",
        query: {
          kdNum: this.merchant.kdNum,
          sntSign: this.merchant.sntSign,
          backExpId: this.backExpId,
          orderSource: this.orderSource || ""
        },
        params: {
          merchant: this.merchant,
          form: util.singleClone(this.form)
        }
      })
    },
    calcPhone(val) {
      return val.replace(/\D/g, '').slice(0, 11)
    },
    getMerchant() {
      if(!this.kdNum && util.query("isIframe") == 1) return
      let loading = this.$loading("正在加载...")
      this.$http.get("/apicenter/kdsnt.do?method=queryMerchant", {
        data: {
          kdNum: this.kdNum,
          backExpId: this.backExpId
        }
      }).then(res => {
        loading.hide()
        this.merchant = res.data
        for(var i = 0; i < (this.merchant.fieldList || []).length; i++) {
          this.$set(this.merchant.fieldList[i], 'value',this.merchant.fieldList[i].defaultValue || "")
        }
        if(!this.state &&  this.notices.length) {
          this.showNotice = true
        }
        this.fillContact()
      })
    },
    fillContact() {
      let merchant = this.merchant, send = this.form.send
      merchant.sendName && (send.name = merchant.sendName)
      merchant.sendMobile && (send.mobile = merchant.sendMobile)
      merchant.sendTel && (send.fixedPhone = merchant.sendTel)
      merchant.sendAddr && (send.address = merchant.sendAddr)
      merchant.sendProvince && (this.xzq = [merchant.sendProvince, merchant.sendCity, merchant.sendDistrict])
    }

  },
  computed: {
    notices: function() {
      return this.merchant.notice ? this.merchant.notice.trim().split(/[\r\n|\n]+/) : []
    },
    state: function() {
      return this.merchant.status === "OPEN" ? "" : this.merchant.status
    }
  },
  watch: {
    xzq(val) {
      this.form.send.xzq = val.join("")
      this.form.send.xzqArr = this.xzq
    },
    'form.send.mobile': function(val) {
      this.form.send.mobile = this.calcPhone(val)
    },
    'form.send.fixedPhone': function(val) {
      this.form.send.fixedPhone = val.replace(/[^\d-_\s()]/g, '').slice(0, 20)
    }
  },
  created() {
    this.getMerchant()
    if(!this.backExpId) {
      this.locate()
    }
  },
  mounted() {
    this.region = new Region({
      level: 2,
      confirm: result => {
        var arr = []
        for (let i = 0, len = result.length; i < len; i++) {
          result[i] && (result[i].name) && arr.push(result[i].name)
        }
        this.xzq = arr
      },
    })
  },
  activated() {
    this.$store.dispatch("removeKeepAliveRoutes", 'dispatch')
  },
  beforeRouteEnter(to, from, next) {
    if(!util.isIOS && !util.isAndroid) {
      location.replace(`https://www.kuaidi100.com/sendback/?kdnum=${to.query.kdNum}&backExpId=${to.query.backExpId}`)
    } else {
      next()
    }
  }

}